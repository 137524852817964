import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/apps/frontend/src/app/[lang]/(landing)/components/Header.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["LanguageDropdown"] */ "/app/apps/frontend/src/app/[lang]/(landing)/components/Language/LanguageDropdown.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["LinkedInScriptTag"] */ "/app/apps/frontend/src/app/[lang]/(landing)/components/LinkedInScriptTag.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["NavbarRoot","Logo","NavbarDesktop","NavbarMobile","NavbarCTA"] */ "/app/apps/frontend/src/app/[lang]/(landing)/components/Navbar/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["MobileProvider"] */ "/app/apps/frontend/src/app/[lang]/(landing)/providers/MobileProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["I18nProvider"] */ "/app/apps/frontend/src/i18n/i18n-provider.tsx");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/google/target.css?{\"path\":\"src/app/[lang]/(landing)/layout.tsx\",\"import\":\"Public_Sans\",\"arguments\":[{\"subsets\":[\"latin\"]}],\"variableName\":\"public_sans\"}");
;
import(/* webpackMode: "eager" */ "/app/apps/frontend/src/styles/tailwind.landing.css");
;
import(/* webpackMode: "eager", webpackExports: ["Toaster"] */ "/app/node_modules/sonner/dist/index.mjs");
